<template>
    <router-view :key="$route.fullPath"></router-view>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useSongs } from "@/hooks/use-songs";

export default defineComponent({
  setup() {
    const { songs, fetchSongs } = useSongs();
    if(!songs.value.length) fetchSongs();
  }
});
</script>